.platformMainBox{
    background-image: url("../../asset/platformBg.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
}
@media screen and (max-width:600px) {
    .platformMainBox{
        
        height: 140vh;
    }
}